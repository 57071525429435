<ng-container *transloco="let t">
  <nav class="layout-breadcrumb flex flex-row ">
    <div>
      @if (hasBackButton$ | async) {
        <button
          class="layout-breadcrumb-back-button"
          pButton
          pRipple
          icon="pi pi-chevron-left"
          [outlined]="true"
          [text]="true"
          [label]="t('ui.back')"
          (click)="onBack()"
        ></button>
      }
    </div>
    <div>
      <ol>
        <ng-template
          ngFor
          let-item
          let-last="last"
          [ngForOf]="breadcrumbs$ | async"
        >
          <li>{{ item.label }}</li>
          <li *ngIf="!last" class="layout-breadcrumb-chevron">/</li>
        </ng-template>
      </ol>
    </div>
  </nav>
</ng-container>
