import { Component, OnInit } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'profitmark-dashboard';

  ngOnInit(): void {
    this._hideSplashScreen();
  }

  private async _hideSplashScreen() {
    if (Capacitor.isPluginAvailable('SplashScreen')) {
      await SplashScreen.hide();
    }
  }
}
