import { Injectable } from '@angular/core';
import {
  Router
} from '@angular/router';

import { PmAuthService } from '../services/pm-auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class PmAuthGuardService {
  constructor(public authService: PmAuthService, public router: Router) {}
  async canActivate() {
    if ((await this.authService.isLoggedIn()) !== true) {
      this.router.navigate(['auth/signin']);
      return false;
    }
    return true;
  }
}
