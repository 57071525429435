import { initializeApp } from 'firebase/app';

import { APP_INITIALIZER, Injector } from '@angular/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';

import { NotificationsFacade } from '../stores';

export const notificationsUpdatesProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (
    notification: NotificationsFacade,
    injector: Injector,
    auth: PmAuthService,
  ) => {
    return async () => {
      const config = injector.get(APP_CONFIG) as AppConfig;
      if (config.firebase && Capacitor.getPlatform() === 'web') {
        initializeApp(config.firebase);
      }
      await FirebaseMessaging.addListener('notificationReceived', (event) => {
        console.log('notificationReceived: ', { event });
        notification.init();
      });

      if (!(await auth.isLoggedIn())) {
        return auth.isLoggedIn$.subscribe(async (isLoggedIn) => {
          if (isLoggedIn) {
            notification.init();
          }
        });
      }

      notification.init();

      return;
    };
  },
  deps: [NotificationsFacade, Injector, PmAuthService],
};
