import {
  Observable,
  catchError,
  combineLatest,
  delay,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap
} from 'rxjs';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { PmCoreVersionCheckService } from './pm-core-version-check.service';

@Injectable({
  providedIn: 'root',
})
export class PmCoreVersionUpdateService {
  constructor(
    private readonly _updates: SwUpdate,
    private readonly _check: PmCoreVersionCheckService,
  ) {}

  versionUpdates$(): Observable<string | null> {
    if (Capacitor.getPlatform() === 'web') {
      return this._checkPwaUpdate$();
    }
    return from(LiveUpdate.ready()).pipe(
      delay(1000),
      switchMap(() => this._checkLiveUpdate$().pipe(filter((v) => !!v))),
    );
  }

  private _checkLiveUpdate$(): Observable<string | null> {
    const bundleId$ = from(LiveUpdate.getBundle()).pipe(
      map((bundle) => bundle.bundleId),
    );
    const check$ = this._check.getCurrentVersion$();
    const appInfo$ = from(App.getInfo());

    return combineLatest([bundleId$, check$, appInfo$]).pipe(
      filter(([_, check]) => !!check),
      filter(([bundleId, check]) => bundleId !== check?.label),
      mergeMap(([bundleId, check, appInfo]) => {
        if (check) {
          if (this._compareVersionNumbers(appInfo.version, check.label)) {
            return from(
              LiveUpdate.downloadBundle({
                bundleId: check.label,
                url: check.archive,
              }),
            ).pipe(map(() => check.label), catchError(() => of(null)));
          }
          if (bundleId) {
            return from(LiveUpdate.reset()).pipe(map(() => null));
          }
          return of(null);
        }
        return of(null);
      }),
    );
  }

  private _checkPwaUpdate$(): Observable<string | null> {
    return this._updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((evt: any) => 'version'),
    );
  }

  private _compareVersionNumbers(
    currentVersion: string,
    newVersion: string,
  ): boolean {
    const current = currentVersion.split('.');
    const latest = newVersion.split('.');

    for (let i = 0; i < current.length; i++) {
      const currentNum = parseInt(current[i], 10);
      const latestNum = parseInt(latest[i], 10);

      if (latestNum > currentNum) {
        return true;
      }
    }

    return false;
  }
}
