import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { Capacitor } from '@capacitor/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { PmCoreVersionUpdateService } from '../services';
import { ToastModule } from 'primeng/toast';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'pm-core-version-update-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    TranslocoModule,
  ],
  templateUrl: './pm-core-version-update-dialog.component.html',
  styleUrls: ['./pm-core-version-update-dialog.component.scss'],
})
export class PmCoreVersionUpdateDialogComponent implements OnInit {
  updates$!: Observable<string | null>;
  promptUpdate = false;
  currentVersion: string | null = '';

  constructor(private readonly _updates: PmCoreVersionUpdateService) {}

  ngOnInit(): void {
    this.updates$ = this._updates.versionUpdates$().pipe(
      tap((currentVersion) => {
        this.promptUpdate = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.currentVersion = currentVersion;
      }),
    );
  }

  async onAccept() {
    if (Capacitor.getPlatform() === 'web') {
      return document.location.reload();
    }
    if (this.currentVersion) {
      await LiveUpdate.setBundle({
        bundleId: this.currentVersion,
      });
      await LiveUpdate.reload();
    }
  }

  onDecline(): void {
    this.promptUpdate = false;
  }
}
