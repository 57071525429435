import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NotificationsEffects } from './+state/notifications.effects';
import { NotificationsFacade } from './+state/notifications.facade';
import * as fromNotifications from './+state/notifications.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromNotifications.NOTIFICATIONS_FEATURE_KEY,
      fromNotifications.notificationsReducer,
    ),
    EffectsModule.forFeature([NotificationsEffects]),
  ],
  providers: [NotificationsFacade],
})
export class PmNotificationStoreModule {}
