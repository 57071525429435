import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as NotificationsActions from './notifications.actions';
import { NotificationsEntity } from './notifications.models';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface NotificationsState extends EntityState<NotificationsEntity> {
  selectedId?: string | number; // which Notifications record has been selected
  loaded: boolean; // has the Notifications list been loaded
  error?: string | null; // last known error (if any)
}

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
}

export const notificationsAdapter: EntityAdapter<NotificationsEntity> =
  createEntityAdapter<NotificationsEntity>();

export const initialNotificationsState: NotificationsState =
  notificationsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialNotificationsState,
  on(NotificationsActions.initNotifications, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    NotificationsActions.loadNotificationsSuccess,
    (state, { notifications }) =>
      notificationsAdapter.setAll(notifications, { ...state, loaded: true }),
  ),
  on(NotificationsActions.loadNotificationsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(NotificationsActions.markAsRead, (state) => ({
    ...state,
    error: null,
  })),
  on(NotificationsActions.markAsReadSuccess, (state, { notification }) =>
    notificationsAdapter.updateOne(
      { id: notification.id, changes: notification },
      state,
    ),
  ),
  on(NotificationsActions.markAsReadFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);

export function notificationsReducer(
  state: NotificationsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
