import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { Observable, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { TranslocoModule } from '@ngneat/transloco';
import { PmAuthService } from '@pm/auth/utils';
import {
  NotificationsFacade,
  PmNotificationDataModule,
} from '@pm/notification/data';
import { Notification } from '@pm/notification/utils';
import { APP_VERSION } from '@pm/core/version';

@Component({
  selector: 'pm-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    BadgeModule,
    RouterModule,
    TranslocoModule,
    ButtonModule,
    PmNotificationDataModule,
  ],
  templateUrl: './pm-sidebar.component.html',
  styleUrls: ['./pm-sidebar.component.scss'],
})
export class PmSidebarComponent implements OnInit {
  @Output() closeSidebar = new EventEmitter<void>();
  public version: string = this.appVersion;
  displayname!: string;

  notificationCount = 0;
  notificationCount$ = this._notificationFacade.unreadNotificationsCount$.pipe(
    tap((count) => (this.notificationCount = count)),
  );
  notification$: Observable<Notification[]> | undefined;
  notifications: Notification[] = [];

  isUpdateAvailable = false;

  constructor(
    private readonly _auth: PmAuthService,
    private readonly _notificationFacade: NotificationsFacade,
    @Inject(APP_VERSION) private readonly appVersion: string,
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this._checkForUpdate();

    this.notification$ = this._notificationFacade.unreadNotifications$.pipe(
      tap((res) => {
        this.notifications = res?.slice(0, 5) || [];
      }),
    );
  }

  async getUserInfo() {
    const user = await this._auth.getDecodedToken();
    if (user?.drupal?.displayname) {
      this.displayname = user.drupal.displayname;
    }
  }

  async logout() {
    await this._auth.signOut();
    window.location.reload();
  }

  close() {
    this.closeSidebar.emit();
  }

  async readNotification(notification: Notification) {
    this._notificationFacade.markAsRead(notification.id);
  }

  async checkNewVersion() {
    if (Capacitor.getPlatform() !== 'web') {
      await LiveUpdate.reload();
    }
  }

  async setLatestVersion() {
    if (Capacitor.getPlatform() === 'web') {
      return document.location.reload();
    }
    const bundles = await LiveUpdate.getBundles();
    const latestBundle = bundles.bundleIds?.[0];
    await LiveUpdate.setBundle({
      bundleId: latestBundle,
    });
    await LiveUpdate.reload();
  }

  private async _checkForUpdate() {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    const bundle = await LiveUpdate.getBundle();
    const bundles = await LiveUpdate.getBundles();

    if (bundles?.bundleIds.length === 0) {
      return;
    }

    const latestBundle = bundles.bundleIds?.[0];
    if (bundle.bundleId !== latestBundle) {
      this.isUpdateAvailable = true;
    }
  }
}
