<ng-container *ngIf="updates$ | async"></ng-container>
<ng-container *transloco="let t; read: 'core'">
  <p-dialog
    [header]="t('update-to-new-version')"
    [modal]="true"
    [(visible)]="promptUpdate"
    [style]="{ width: '25rem' }"
    [position]="'top'"
    styleClass="safe-area-inset-top"
  >
    <span class="p-text-secondary block mb-5">
      {{
        t('recommendation-to-update', {
          currentVersion
        })
      }}
    </span>
    <div class="flex justify-content-end gap-2">
      <p-button
        [label]="t('decline')"
        severity="secondary"
        (click)="onDecline()"
      />
      <p-button [label]="t('confirm')" (click)="onAccept()" />
    </div>
  </p-dialog>
</ng-container>
