export enum AppType {
  landing = 'LANDING',
  dashboard = 'DASHBOARD',
}

export enum PmServiceType {
  all = 'all',
  hosting = 'hosting',
  domain = 'domain',
  application = 'application',
  trademark = 'trademark',
  monitoring = 'monitoring',
}
