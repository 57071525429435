export const PUBLIC_ENDPOINTS = [
  '/auth/signin',
  '/auth/signup',
  '/auth/refresh',
  '/auth/verify',
  '/auth/forgot-password',
  '/auth/reset-password',
];

export const AUTH_ENDPOINTS = ['jwt/token', 'jwt/refresh'];
