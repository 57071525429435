import { BadgeModule } from 'primeng/badge';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NotificationsFacade } from '@pm/notification/data';

@Component({
  selector: 'pm-notification-counter',
  standalone: true,
  imports: [CommonModule, BadgeModule],
  templateUrl: './pm-notification-counter.component.html',
  styleUrls: ['./pm-notification-counter.component.scss'],
})
export class PmNotificationCounterComponent {
  notificationCount$ = this._notificationFacade.unreadNotificationsCount$;
  constructor(private readonly _notificationFacade: NotificationsFacade) {}
}
