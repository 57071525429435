import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { LayoutService } from './service/app.layout.service';
import { PmAuthService } from '@pm/auth/utils';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styles: [
    `
      :host.sticked .layout-topbar {
        background-color: var(--surface-ground);
      }
    `,
  ],
})
export class AppTopbarComponent implements OnInit, OnDestroy {
  @ViewChild('menubutton') menuButton!: ElementRef;

  displayname!: string;
  stickinessObserver!: IntersectionObserver;

  constructor(
    public layoutService: LayoutService,
    private readonly _auth: PmAuthService,
    private readonly _el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this._trackStickiness();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  async getUserInfo() {
    const user = await this._auth.getDecodedToken();
    if (user?.drupal?.displayname) {
      const initials = user.drupal.displayname
        .split(' ')
        .map((n: string) => n[0]);
      this.displayname = initials.slice(0, 2).join('');
    }
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }
  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  private _trackStickiness() {
    this.stickinessObserver = new IntersectionObserver(
      ([entry]) => {
        this._el.nativeElement.classList.toggle(
          'sticked',
          !entry.isIntersecting,
        );
      },
      { threshold: 1 },
    );
    this.stickinessObserver.observe(this._el.nativeElement);
  }
}
