<div class="layout-topbar">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger"
      (click)="onMenuButtonClick()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
  </div>

  <div class="topbar-end">
    <ul class="topbar-menu">
      <li class="topbar-profile">
        <button type="button" class="p-link" (click)="onProfileButtonClick()">
          <p-avatar [label]="displayname" shape="circle"></p-avatar>
        </button>
        <div style="margin-left: -6px; margin-top: -24px">
          <pm-notification-counter></pm-notification-counter>
        </div>
      </li>
    </ul>
  </div>
</div>
