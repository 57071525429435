import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private appConfig: AppConfig = inject(APP_CONFIG) as AppConfig;

  getTranslation(lang: string) {
    return this.http.get<Translation>(
      `/assets/i18n/${lang}.json`
    );
  }
}
