/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';

import { NotificationsEntity } from './notifications.models';

export const initNotifications = createAction('[Notifications/API] Fetch');

export const loadNotificationsSuccess = createAction(
  '[Notifications/API] Load Notifications Success',
  props<{ notifications: NotificationsEntity[] }>(),
);

export const loadNotificationsFailure = createAction(
  '[Notifications/API] Load Notifications Failure',
  props<{ error: any }>(),
);

export const markAsRead = createAction(
  '[Notifications/API] Mark as read',
  props<{ id: number }>(),
);

export const markAsReadSuccess = createAction(
  '[Notifications/API] Mark as read success',
  props<{ notification: NotificationsEntity }>(),
);

export const markAsReadFailure = createAction(
  '[Notifications/API] Mark as read failure',
  props<{ error: any }>(),
);

export const refetchNotifications = createAction(
  '[Notifications/API] Refetch Notifications',
);
