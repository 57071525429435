/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, NgZone } from '@angular/core';
import {
  FirebaseMessaging,
  GetTokenOptions,
} from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmPorfileDataUserService } from '@pm/profile/data';
import { initializeApp } from 'firebase/app';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PmNotificationPushService {
  get isEnabled(): boolean {
    return !!localStorage.getItem('fcm_token');
  }

  constructor(
    private readonly _platform: Platform,
    @Inject(APP_CONFIG) private _config: AppConfig,
    private readonly ngZone: NgZone,
    private readonly _user: PmPorfileDataUserService,
  ) {
    this._initializeFirebase();
  }

  async init() {
    if (!localStorage.getItem('fcm_token')) {
      await this._subscribeToNotifications();
    } else {
      await this._addTokenToUser();
    }
  }

  async removeToken() {
    const token = localStorage.getItem('fcm_token');
    if (token) {
      await FirebaseMessaging.deleteToken();
      await this._removeTokenFromUser();
      localStorage.removeItem('fcm_token');
    }
  }

  async addListeners(): Promise<void> {
    await FirebaseMessaging.addListener('notificationReceived', (event) => {
      console.log('notificationReceived: ', { event });
    });
    if (Capacitor.getPlatform() === 'web') {
      navigator.serviceWorker.addEventListener('message', (event: any) => {
        console.log('serviceWorker message: ', { event });
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body,
        });
        notification.onclick = (event) => {
          console.log('notification clicked: ', { event });
        };
      });
    }
  }

  private async _initializeFirebase(): Promise<void> {
    if (this._config.firebase && !this._platform.is('capacitor')) {
      initializeApp(this._config.firebase);
    }
  }

  private async _subscribeToNotifications(): Promise<void> {
    FirebaseMessaging.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        this._getToken();
      } else {
        console.error('Permission denied');
      }
    });
  }

  private async _getToken(): Promise<void> {
    const options: GetTokenOptions = {
      vapidKey: this._config.firebase?.vapidKey,
    };
    if (Capacitor.getPlatform() === 'web') {
      const reg = await this._getSWRegistration();
      options.serviceWorkerRegistration = reg as ServiceWorkerRegistration;
    }
    try {
      const { token } = await FirebaseMessaging.getToken(options);
      localStorage.setItem('fcm_token', token);
      await this._addTokenToUser();
    } catch (error) {
      console.error(error);
    }
  }

  private async _addTokenToUser(): Promise<void> {
    const token = localStorage.getItem('fcm_token');
    if (token) {
      const user = await firstValueFrom(this._user.getUserData$());
      if (!user) {
        return;
      }
      const tokens = Array.isArray(user.field_fcm_tokens)
        ? user.field_fcm_tokens
        : user.field_fcm_tokens
          ? [user.field_fcm_tokens]
          : [];

      await firstValueFrom(
        this._user.updateUserData$({
          field_fcm_tokens: [...tokens, token],
        }),
      );
    }
  }

  private async _removeTokenFromUser(): Promise<void> {
    const token = localStorage.getItem('fcm_token');
    if (token) {
      const user = await firstValueFrom(this._user.getUserData$());
      if (!user) {
        return;
      }
      const tokens = Array.isArray(user.field_fcm_tokens)
        ? user.field_fcm_tokens
        : user.field_fcm_tokens
          ? [user.field_fcm_tokens]
          : [];
      const newTokens = tokens.filter((t) => t !== token);
      await firstValueFrom(
        this._user.updateUserData$({
          field_fcm_tokens: newTokens,
        }),
      );
    }
  }

  private async _getSWRegistration(): Promise<
    ServiceWorkerRegistration | undefined | null
  > {
    const serviceWorkerName = 'firebase-messaging-sw.js';
    const sw = await navigator.serviceWorker.getRegistration(serviceWorkerName);
    if (!sw) {
      const registration = await navigator.serviceWorker.register(
        serviceWorkerName,
        {
          scope: '/firebase-cloud-messaging-push-scope',
        },
      );
      return registration;
    }
    return sw;
  }
}
