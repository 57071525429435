import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  NOTIFICATIONS_FEATURE_KEY,
  NotificationsState,
  notificationsAdapter,
} from './notifications.reducer';

// Lookup the 'Notifications' feature state managed by NgRx
export const selectNotificationsState =
  createFeatureSelector<NotificationsState>(NOTIFICATIONS_FEATURE_KEY);

const { selectAll, selectEntities } = notificationsAdapter.getSelectors();

export const selectNotificationsLoaded = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.loaded,
);

export const selectNotificationsError = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.error,
);

export const selectAllNotifications = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => selectAll(state),
);

export const selectNotificationsEntities = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => selectEntities(state),
);

export const selectSelectedId = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.selectedId,
);

export const selectEntity = createSelector(
  selectNotificationsEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
);

export const selectNotificationsCount = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => Object.keys(state.entities).length,
);

export const selectUnreadNotitifications = createSelector(
  selectAllNotifications,
  (notifications) => notifications.filter((notification) => !notification.read),
);

export const selectReadNotitifications = createSelector(
  selectAllNotifications,
  (notifications) => notifications.filter((notification) => notification.read),
);

export const selectUnreadNotitificationsCount = createSelector(
  selectUnreadNotitifications,
  (notifications) => notifications.length,
);
