import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';

import { JwtToken } from '../models';
import { PmAuthService } from '../services/pm-auth-service.service';

export const authSsoProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (auth: PmAuthService, http: HttpClient, appConfig: AppConfig) => {
    return async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sso = urlParams.get('sso');
      if (sso) {
        try {
          const tokens$ = http.get<JwtToken>(appConfig.api + '/jwt/token', {
            params: {
              _format: 'json',
            },
            headers: {
              Authorization: 'Bearer ' + sso,
            },
          });

          const tokens = await firstValueFrom(tokens$);
          await auth.setTokens(tokens);
        } catch (error) {
          await auth.setToken(sso);
        }

        const allOtherParams = new URLSearchParams(window.location.search);
        allOtherParams.delete('sso');
        history.replaceState(
          null,
          '',
          allOtherParams.toString()
            ? window.location.pathname + '?' + allOtherParams
            : window.location.pathname,
        );
      }
      return;
    };
  },
  deps: [PmAuthService, HttpClient, APP_CONFIG],
};
