<ng-container *transloco="let t">
  <div class="pm-core-page-not-found">
    <div class="pm-core-page-not-found__content py-4">
      <h1 class="text-center">{{ t('page-not-found') }}</h1>

      <p class="text-center">
        {{ t('page-not-found-message') }}
      </p>

      <div
        class="flex flex-column md:flex-row gap-2 align-items-center justify-content-center"
      >
        <a pButton routerLink="/" class="btn btn-primary">
          {{ t('go-to-home') }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
