import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PaginatedResponse, PmCoreDrupalMapperService } from '@pm/core/utils';
import {
  Notification,
  NotificationDrupalResponse,
  NotificationQueryParams,
} from '@pm/notification/utils';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PmNotificationService {
  notificationBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) private _config: AppConfig,
    private readonly _http: HttpClient,
    private readonly _mapper: PmCoreDrupalMapperService,
    private manager: HttpCacheManager,
  ) {}

  getNotifications$(
    query: NotificationQueryParams,
  ): Observable<PaginatedResponse<Notification>> {
    const cleaned = this._mapper.cleanParams(query);
    const params = new HttpParams({
      fromObject: cleaned,
    });

    return this._http
      .get<PaginatedResponse<NotificationDrupalResponse>>(
        `${this._config.api}/user/profit/notifications`,
        {
          params,
        },
      )
      .pipe(
        map((res) => ({
          ...res,
          rows: res.rows.map((notification: NotificationDrupalResponse) =>
            this._mapper.format<Notification>(notification),
          ),
        })),
      );
  }

  updateNotification$(
    id: number,
    payload: Partial<Notification>,
  ): Observable<Notification> {
    const body = this._mapper.drupalizeObject(payload);
    return this._http
      .patch<NotificationDrupalResponse>(
        `${this._config.api}/admin/profit/notification/${id}`,
        body,
        {
          params: {
            _format: 'json',
          },
        },
      )
      .pipe(
        tap(() => this.manager.delete(this.notificationBucket)),
        map((res) => this._mapper.format<Notification>(res)),
      );
  }
}
