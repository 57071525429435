<div class="status-bar"></div>
<div
  class="layout-container"
  [ngClass]="containerClass"
>
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper relative">
    <app-topbar></app-topbar>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu></app-profilemenu>
  <!-- <app-config></app-config> -->
  <div class="layout-mask"></div>
</div>
