import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { APP_VERSION } from '../tokens';
import { AppVersionResponse } from '../models';
import { App } from '@capacitor/app';
import { catchError, from, of, switchMap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PmCoreVersionCheckService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _http: HttpClient,
    @Inject(APP_VERSION) public version: string,
  ) {}

  getCurrentVersion$() {
    return from(App.getInfo()).pipe(
      switchMap((info) => {
        return this._http
          .get<AppVersionResponse>(
            `${this.appConfig.api}/public/profit/version/latest`,
            {
              params: {
                identifier: info.id,
                binary_version: info.version,
                _format: 'json',
              },
            },
          )
          .pipe(catchError(() => of(null)));
      }),
    );
  }
}
