import { LOCALE_ID, Provider } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export class LocaleId extends String {
  constructor(private localeService: TranslocoService) {
    super();
  }

  override toString(): string {
    const lang = this.localeService.getActiveLang();
    if (lang === 'en') {
      return 'es-ES';
    }
    return lang ?? 'es-ES';
  }

  override valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [TranslocoService],
};
