import { catchError, map, of, switchMap } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { PmNotificationService } from '../../../services';
import * as NotificationsActions from './notifications.actions';
import * as NotificationsFeature from './notifications.reducer';

@Injectable()
export class NotificationsEffects {
  private actions$ = inject(Actions);
  private readonly notification = inject(PmNotificationService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.initNotifications),
      switchMap(() => {
        return this.notification
          .getNotifications$({
            items_per_page: 100,
          })
          .pipe(
            map((data) =>
              NotificationsActions.loadNotificationsSuccess({
                notifications: data.rows,
              }),
            ),
            catchError((error) =>
              of(NotificationsActions.loadNotificationsFailure({ error })),
            ),
          );
      }),
      catchError((error) => {
        console.error('Error', error);
        return of(NotificationsActions.loadNotificationsFailure({ error }));
      }),
    ),
  );

  read$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.markAsRead),
      switchMap(({ id }) => {
        return this.notification.updateNotification$(id, { read: true }).pipe(
          map((data) =>
            NotificationsActions.markAsReadSuccess({
              notification: data,
            }),
          ),
          catchError((error) =>
            of(NotificationsActions.markAsReadFailure({ error })),
          ),
        );
      }),
    ),
  );
}
