import '@capacitor-community/safe-area';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { NG_PROGRESS_CONFIG, NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgxStripeModule } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import {
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_ID,
  Injector,
  NgModule,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { PersistStateModule } from '@ngrx-addons/persist-state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { authInterceptorProviders, authSsoProvider } from '@pm/auth/utils';
import { getConfigValueFactory } from '@pm/config';
import { AppLayoutModule } from '@pm/core/layout';
import { LocaleProvider } from '@pm/core/locale';
import { APP_TYPE, AppType } from '@pm/core/utils';
import { PmCoreVersionUpdateDialogComponent } from '@pm/core/version';
import { PmNotificationComponent } from '@pm/notification';
import {
  PmNotificationDataModule,
  notificationsUpdatesProvider,
} from '@pm/notification/data';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {
      canceledNavigationResolution: 'replace',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    AppLayoutModule,
    TranslocoRootModule,
    RecaptchaV3Module,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name: 'Profitmark Dashboard',
    }),
    EffectsModule.forRoot([]),
    PersistStateModule.forRoot(),
    NgxStripeModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    PmNotificationComponent,
    PmCoreVersionUpdateDialogComponent,
    PmNotificationDataModule,
    ToastModule,
    NgProgressModule,
  ],
  providers: [
    { provide: APP_ID, useValue: 'profitmarkClient' },
    { provide: APP_TYPE, useValue: AppType.dashboard },
    importProvidersFrom(NgProgressHttpModule),
    provideHttpClient(
      withFetch(),
      withInterceptors([withHttpCacheInterceptor()]),
      withInterceptorsFromDi(),
    ),
    provideHttpCache(),
    ...authInterceptorProviders,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: getConfigValueFactory('recaptchaKey'),
      deps: [Injector],
    },
    LocaleProvider,
    authSsoProvider,
    notificationsUpdatesProvider,
    MessageService,
    {
      provide: NG_PROGRESS_CONFIG,
      useValue: {
        color: '#6366f1',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
